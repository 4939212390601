<template>
    <div class="other_page_r">
        <el-card class="card_box">
            <div slot="header" class="card_title">
                <span>权限管理/人员信息</span>
            </div>
            <div>
                <el-form :inline="true" :model="search" class="demo-form-inline">
                    <el-form-item label="名称">
                        <el-input class="custom_input_170" v-model="searchInfo.keywords" placeholder=""></el-input>
                    </el-form-item>
                   
                 
                    <el-form-item>
                        <el-button type="primary" @click="getDataList">查询</el-button>
                        <el-button type="primary" @click="reset">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
         
            <div class="table_box">
                <tablec :tableData="tableData"></tablec>
            </div>
            <div class="flex justify_content_end aligin_items_c table_footer custom_btn">
                <div>

                    <pagination v-show="total > 0" :total="total" :page.sync="searchInfo.page"
                        :limit.sync="searchInfo.size" @pagination="getDataList" />


                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
    import tablec from "./tablec.vue"
    import { getAccountLists } from "@/api/limitsauthority";
    import { searchMixin } from "@/utils/searchMixin.js";
    export default {
        mixins: [searchMixin],
        components: {tablec},
        name: '',
        data() {
            return {
                tableData: [],
                total: Number,
                searchInfo: {
                    page: 1,
                    size: 10,
                    keywords: ''
                },
              
            }
        },
        created() {
            this.getDataList()
        },
        methods: {

            getDataList() {
                getAccountLists(this.searchInfo).then((res) => {
                    if (res.code == 200) {
                        this.tableData = res.data.data
                        this.total = res.data.total;
                    } else {

                        this.tableData = []
                        this.total = 0;
                        
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.$message.error(error.message);
                });
            }
        }
  
    }
</script>

<style scoped>

</style>