<template>
    <div class="table">
        <el-table border ref="multipleTable" :data="tableData" :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}" tooltip-effect="dark" style="width: 100%"
            height="60vh"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55">
            </el-table-column>

            <el-table-column prop="id" label="序号">
            </el-table-column>
            <el-table-column prop="account" label="账号" width="120">
            </el-table-column>
            <el-table-column prop="truename" label="姓名" width="200">

            </el-table-column>

            <el-table-column prop="mobile" label="手机号码" width="200">

            </el-table-column>
            <el-table-column label="账号状态">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.state" disabled="" active-value="1" inactive-value="2" active-color="#13ce66"
                        inactive-color="#ff4949">
                    </el-switch>
                </template>
            </el-table-column>

            <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                    <span class="pointer" @click="toDelete(scope.row)">删除</span>
                    <span class="pointer margin_left_10" @click="toEdit(scope.row)">编辑</span>
                </template>
            </el-table-column>
        </el-table>

    </div>
</template>

<script>
    import { delAccount } from "@/api/limitsauthority";
    export default {
        props: ["tableData"],
        name: '',
        data() {
            return {
                value: true,

            }
        },
        methods: {
            toEdit(row) {
                this.$router.push({
                    path: "/limitsauthority/addpersonnel",
                    query: { row: row, type: "edit" }
                })
            },
            toDelete(row) {
                this.$confirm('此操作将删除该条人员, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    delAccount({ id: row.id }).then((res) => {
                        if (res.code == 200) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.$parent.$parent.getDataList()
                        }
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });

            },
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            }
        }
    }
</script>

<style scoped>


</style>