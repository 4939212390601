import request from "@/utils/request";

//获取账号列表
export function getAccountLists(obj) {
	return request({
		url: "/admin/authority/getAccountLists",
		method: "post",
		data: obj,
	});
}
//删除账号
export function delAccount(obj) {
	return request({
		url: "/admin/authority/delAccount",
		method: "post",
		data: obj,
	});
}
//新增账号
export function addAccount(obj) {
	return request({
		url: "/admin/authority/addAccount",
		method: "post",
		data: obj,
	});
}
//编辑账号
export function editAccount(obj) {
	return request({
		url: "/admin/authority/editAccount",
		method: "post",
		data: obj,
	});
}
//获取角色列表--账号管理
export function getRoles(obj) {
	return request({
		url: "/admin/authority/getRoles",
		method: "post",
		data: obj,
	});
}
//获取角色列表
export function getRolesLists(obj) {
	return request({
		url: "/admin/authority/getRolesLists",
		method: "post",
		data: obj,
	});
}
//添加角色
export function addRoles(obj) {
	return request({
		url: "/admin/authority/addRoles",
		method: "post",
		data: obj,
	});
}
//编辑角色
export function editRoles(obj) {
	return request({
		url: "/admin/authority/editRoles",
		method: "post",
		data: obj,
	});
}
//删除角色
export function delRoles(obj) {
	return request({
		url: "/admin/authority/delRoles",
		method: "post",
		data: obj,
	});
}
//修改角色状态
export function disRoles(obj) {
	return request({
		url: "/admin/authority/disRoles",
		method: "post",
		data: obj,
	});
}

//分配角色权限
export function assignRolesPermission(obj) {
	return request({
		url: "/admin/authority/assignRolesPermission",
		method: "post",
		data: obj,
	});
}
// 获取权限列表

export function authLists(obj) {
	return request({
		url: "/admin/authority/authLists",
		method: "post",
		data: obj,
	});
}
